<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.announcement.list' }">
                Duyuru Yönetimi
              </router-link>
            </li>
            <li>Duyuruyu Düzenle</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>Duyuruyu Düzenle</span></h2>
        </div>
        <div class="section-header-right">
          <button type="submit" @click="saveForm" class="send-btn d-inline-block btn btn-default float-right">
            {{ $t("general.save") }} <i class="uil-message"></i>
          </button>
        </div>
      </div>


      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <fieldset class="uk-fieldset">
        <div class="uk-margin">
          <input id="title" class="uk-input" type="text" v-model="item.name"
            placeholder="Duyuru Başlığını Buraya Yazınız" />
        </div>
        <div class="uk-margin">
          <Summernote @change="value => { updateTemplateCode(value) }" :forceUpdate="true" :model="text" :config="{}"
            height="300px" name="test" style="background-color:#fff;margin:0 auto;" class="mb-4"></Summernote>
        </div>
        <hr />

        <div class="uk-margin">
          <label for="type">Gönderim Özelleştirme</label>

          <multiple-select-input :model.sync="view_status" :multiple="false" :required="false" :searchable="false"
            :showLabels="false" :values="SendType" :allowEmpty="false" @select="view_status = $event; changeStatus()" />

          <div class="label-container" v-show="item.view_all == 0">
            <div class="label-area d-flex">
              <div>
                <span class="uk-label my-1 d-inline-flex" v-for="(user, index) in checked_users_items"
                  :key="'user_checked_item_box_' + index">
                  <p><i class="uil-user mr-2"></i>{{ user.fullname }}</p>
                  <button class="cursor-pointer ml-2" @click="removeCheckedUser(user.id)">
                    <i class="uil-multiply"></i>
                  </button>
                </span>
                <span class="uk-label my-1 d-inline-flex" v-for="(group, index) in checked_groups_items"
                  :key="'group_checked_item_box_' + index">
                  <p><i class="uil-user mr-2"></i>{{ group.name }}here</p>
                  <button type="button" @click="removeCheckedGroup(group.id)" class="cursor-pointer ml-2">
                    <i class="uil-multiply"></i>
                  </button>
                </span>
              </div>
            </div>
            <hr />
            <button type="button" uk-toggle="target: #modal_user_group_add"
              class="btn btn btn-outline-secondary btn-block">
              <i class="uil-plus"></i> Kullanıcı/Grup Ekle
            </button>
            <div id="modal_user_group_add" class="uk-modal-full uk-modal" uk-modal="">
              <div class="uk-modal-footer bggray">
                <h3 class="float-left">
                  <i class="uil-presentation-plus"></i>
                  Kullanıcı/Grup Ekle
                </h3>
                <button class="
                    uk-button uk-button-default
                    small
                    uk-modal-close
                    float-right
                  " type="button">
                  <i class="uil-times"></i> Kapat
                </button>
              </div>

              <div class="uk-modal-dialog bggray h-100">
                <div class="uk-padding-large position-relative uk-grid pt-0" uk-grid="">
                  <div class="uk-width-5-5">
                    <UserGroupSelection class="bg-white px-2 py-4 card" :with_description="true"
                      :allow_both_selection="true" :added_users="item.checked_users" :added_groups="checked_groups"
                      @setCheckedUsers="($event) => {
                          checked_users = $event.ids;
                          checked_users_items = $event.items;
                        }
                        " @setCheckedGroups="($event) => {
      checked_groups = $event.ids;
      checked_groups_items = $event.items;
    }
    "></UserGroupSelection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <button type="submit" @click="saveForm" class="send-btn d-inline-block btn btn-default float-right">
            {{ $t("general.save") }} <i class="uil-message"></i>
          </button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import UserGroupSelection from "@/view/components/assignment/UserGroupSelection";
import store from "@/core/services";
import Summernote from "@/view/company/Mail/Summernote";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";


import module, {
  BASE_URL,
  GET_ITEM,
  UPDATE_ITEM,
  ITEM,
  MODULE_NAME,
  ERROR,
} from "@/core/services/store/announcement.module";


export default {
  name: "EditAnnouncement",
  components: { UserGroupSelection, Summernote, MultipleSelectInput },

  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
  },
  data() {
    return {
      view_status: null,
      successMessage: null,
      errorMessages: [],
      checked_users_items: [],
      checked_users: [],
      checked_groups_items: [],
      checked_groups: [],
      text: null,
      SendType: [
        {
          id: 0,
          name: "Belli Kullancılara/Gruplara Gönder"
        },
        {
          id: 1,
          name: "Tüm Kullanıcılara Gönder"
        }
      ]
    };
  },

  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    changeStatus() {
      this.item.view_all = this.view_status.id
    },
    updateTemplateCode(value) {
      this.item.text = value
    },
    getItem() {
      store
        .dispatch(MODULE_NAME + "/" + GET_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
        })
    },
    saveForm() {
      let self = this;
      let item = self.item
      if (item.name == null || item.name.length < 3) {
        this.errorMessages.push(
          self
            .$t("validation.required_field")
            .replace(":NAME", 'Başlık')
        );
        self.scrollToTop();
        return false;
      }

      if (item.text == null || item.text.length < 3) {
        this.errorMessages.push(
          self
            .$t("validation.required_field")
            .replace(":NAME", 'Açıklama')
        );
        self.scrollToTop();
        return false;
      }

      let formData = {
        name: item.name,
        text: item.text,
        users: self.checked_users,
        status_id: item.status_id == 1 ? 1 : 0,
        all_users_notice: item.view_all == 1 ? 1 : 0,
        groups: self.checked_groups,
      }

      store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
          contents: formData,
        })
        .then(() => {
          self.scrollToTop();
          if (!this.error) {
            this.item.checked_users = this.checked_users
            this.$router.push({ name: "company.announcement.list" });
          } else this.errorMessages.push(this.error)
        });
    },
    removeCheckedUser(id) {
      let pos = this.getIndex(id, this.checked_users);
      this.checked_users.splice(pos, 1);
    },
    removeCheckedGroup(id) {
      let pos = this.getIndex(id, this.checked_groups);
      this.checked_groups.splice(pos, 1);
    },
    getIndex(id, array) {
      let index = array
        .map(function (item) {
          return item;
        })
        .indexOf(id);
      return index;
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    $(".selectpicker").selectpicker();
    this.getItem()
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_user_group_add");
    if (modal) modal.$destroy(true);
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  watch: {
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
    item: function (data) {
      this.text = data.text
      this.view_status = this.SendType[data.view_all]

    }
  },
};
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
